import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { InteractionModule } from './../interaction/interaction.module';
import {
  DefiTokensEffects,
  DEFI_TOKEN_METADATA_PROVIDER,
  AssetDefiTokenProvider,
} from './store/defi-tokens.effects';
import { erc20Reducer, web3Reducer } from './store/defi-tokens.reducer';
import { PrintTokenPipe } from './pipes/print-token.pipe';
import { FormatDatePipe } from './pipes/format-date.pipe';

@NgModule({
  declarations: [
    PrintTokenPipe,
    FormatDatePipe
  ],
  providers: [
    {
      multi: true,
      provide: DEFI_TOKEN_METADATA_PROVIDER,
      useClass: AssetDefiTokenProvider,
    },
  ],
  imports: [
    InteractionModule,
    EffectsModule.forFeature([DefiTokensEffects]),
    CommonModule,
    StoreModule.forRoot({ erc20: erc20Reducer, defaultAccount: web3Reducer }),
  ],
  exports: [
    PrintTokenPipe,
    FormatDatePipe
  ]
})
export class DefiTokensModule {}
