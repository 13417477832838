import { MatDialogModule } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { StakingComponent } from './components/stake/staking.component';
import { TokenAmountInputComponent } from './components/token-amount-input/token-amount-input.component';
import { StakingFormComponent } from './components/staking-form/staking-form.component';
import { CurrentStakeComponent } from './components/current-stake/current-stake.component';
import { DefiTokensModule } from '../defi-tokens/defi-tokens.module';
import { InteractionModule } from '../interaction/interaction.module';
import { LockedStakeDialogComponent } from './components/locked-stake-dialog/locked-stake-dialog.component';

@NgModule({
  declarations: [
    StakingComponent,
    TokenAmountInputComponent,
    StakingFormComponent,
    StakingFormComponent,
    CurrentStakeComponent,
    LockedStakeDialogComponent
  ],
  exports: [StakingComponent],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    InteractionModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatFormFieldModule,
    MatIconModule,
    DefiTokensModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatTabsModule,
    MatSelectModule,
    MatDialogModule
  ],
})
export class StakingModule {}
