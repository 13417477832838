import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { OraoState } from './orao/store/orao.selectors';
import { Web3Service } from './interaction/services/web3/web3.service';
import { oraoMetaLoad } from './orao/store/orao.actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'orao-ui';
  constructor(private store: Store<OraoState>, private web3: Web3Service) {
  }

  async ngOnInit() {
    // await this.web3.connectAccount();
    // const web3 = await this.web3.web3
    //   .toPromise()
    // this.store.dispatch(oraoMetaLoad())
  }
}
