import { Component, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import {
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { combineLatest, ReplaySubject } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { BigNumber } from 'bignumber.js';

import { AppState } from 'src/app/defi-tokens/store/defi-tokens.selectors';
import { erc20MetaLoad } from '../../../defi-tokens/store/defi-tokens.actions';
import {
  selectErc20Meta,
  selectDefaultAccount,
  successFilter,
} from '../../../defi-tokens/store/defi-tokens.selectors';



@Component({
  selector: 'app-token-amount-input',
  templateUrl: './token-amount-input.component.html',
  styleUrls: ['./token-amount-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TokenAmountInputComponent),
    multi: true
  }]
})
export class TokenAmountInputComponent implements OnInit, ControlValueAccessor {
  @Input()
  set address(value: string) {
    this._address.next(value);
  }
  _address = new ReplaySubject<string>(1);
  tokenMeta$ = this._address.pipe(switchMap(address => this.store.select(selectErc20Meta(), {
    address
  })), successFilter(), map(x => x.result))
  tokenBalance$ = combineLatest([this.tokenMeta$, this.store.select(selectDefaultAccount).pipe(successFilter())]).pipe(
    map(([meta, account]) => meta.balance[account.result])
  )
  value = '0'

  @Input()
  showingReturn: boolean = false;

  constructor(private controlContainer: ControlContainer, private store: Store<AppState>) { }


  @ViewChild(FormControlDirective)
  set formControlDirective(value: FormControlDirective) {
    if (value) {
      this._formControlDirective.next(value)
    }
  };
  _formControlDirective = new ReplaySubject<FormControlDirective>(1);
  @Input()
  formControl!: FormControl;

  @Input()
  formControlName!: string;
  /* get hold of FormControl instance no matter formControl or    formControlName is given. If formControlName is given, then this.controlContainer.control is the parent FormGroup (or FormArray) instance. */
  get control() {
    return this.formControl || this.controlContainer.control!.get(this.formControlName) || new FormControl(this.value);
  }


  clearInput() {
    this.control.setValue('');
  }

  registerOnTouched(fn: any): void {
    this._formControlDirective.subscribe(formControlDirective => formControlDirective.valueAccessor!.registerOnTouched(fn));
  }

  registerOnChange(fn: any): void {
    this._formControlDirective.pipe(switchMap(x => this.tokenMeta$.pipe(map(meta => [x, meta] as const)))).subscribe(([formControlDirective, meta]) => formControlDirective.valueAccessor!.registerOnChange((val: number) => fn(new BigNumber(val).multipliedBy(new BigNumber(10).pow(meta.decimals)).toFixed())));
  }

  async writeValue(obj: any) {
    const formControlDirective = await this._formControlDirective.pipe(first()).toPromise();
    const meta = await this.tokenMeta$.pipe(first()).toPromise();
    this.value = new BigNumber(obj).dividedBy(new BigNumber(10).pow(meta.decimals)).toFixed(6);
    formControlDirective.valueAccessor!.writeValue(this.value)
  }

  async setDisabledState(isDisabled: boolean) {
    const formControlDirective = await this._formControlDirective.pipe(first()).toPromise();
    formControlDirective.valueAccessor!.setDisabledState!(isDisabled)
  }

  ngOnInit(): void {
    this._address.subscribe(async address => {
      const account = await this.store.select(selectDefaultAccount).pipe(successFilter(), first())
        .toPromise()
      await this.store.dispatch(erc20MetaLoad({
        address
      }))
      const erc = await this.store.select(selectErc20Meta(), { address }).pipe(successFilter(), first())
        .toPromise()
    })
  }

}
