import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

import { AppState } from 'src/app/defi-tokens/store/defi-tokens.selectors';
import { environment } from '../../../../environments/environment';
import { oraoStake, oraoUnstake } from '../../../orao/store/orao.actions';
import { selectOraoMeta, OraoState } from '../../../orao/store/orao.selectors';
import { oraoClaim } from '../../../orao/store/orao.actions';
import { erc20MetaLoad } from '../../../defi-tokens/store/defi-tokens.actions';
import {
  selectErc20Meta,
  selectDefaultAccount,
  successFilter,
} from '../../../defi-tokens/store/defi-tokens.selectors';

@Component({
  selector: 'app-staking',
  templateUrl: './staking.component.html',
  styleUrls: ['./staking.component.scss'],
})
export class StakingComponent implements OnInit {
  orao$ = this.store
    .select(selectErc20Meta(), { address: environment.oraoAddress })
    .pipe(
      successFilter(),
      map((x) => x.result)
    );

  oraoDecimals$ = this.orao$.pipe(
    filter((x) => !!x),
    map((x) => x!.decimals)
  );
  oraoBalance$ = combineLatest([
    this.orao$,
    this.store.select(selectDefaultAccount).pipe(successFilter()),
  ]).pipe(map(([orao, account]) => orao.balance[account.result]));

  oraoClaimablePerPool$ = this.oraoStore
    .select(selectOraoMeta)
    .pipe(map((x) => x.claimablePerPool));

  stakes$ = this.oraoStore.select(selectOraoMeta).pipe(map((x) => x.stakes));
  stakingContracts$ = this.oraoStore.select(selectOraoMeta).pipe(map((x) => x.stakingContracts));

  inputAmount: string = '0';

  constructor(
    private store: Store<AppState>,
    private oraoStore: Store<OraoState>
  ) {}

  async ngOnInit(): Promise<void> {
    this.store.dispatch(
      erc20MetaLoad({
        address: environment.oraoAddress,
      })
    );
  }

  claim() {
    this.store.dispatch(oraoClaim());
  }

  async stake(stake: {period: string, amount: string }) {
    this.store.dispatch(
      oraoStake({
        amount: stake.amount,
        period: stake.period,
      })
    );
  }

  async unstake(stake: { kek_id: string; period: string }) {
    this.store.dispatch(
      oraoUnstake({
        id: stake.kek_id,
        period: stake.period,
      })
    );
  }
}
