import { createAction, props } from '@ngrx/store';
import { BigNumber } from 'ethers';

export const oraoMetaLoad = createAction('[OraoMeta] Load');
export const oraoMetaSuccess = createAction('[OraoMeta] Success', props<{
  orao: {
    address: string
  },
  claimable: string,
  claimablePerPool: {
    pool: string,
    claimable: string
  }[],
  stakes: {
    kek_id: string;
    user: string;
    value_d18: BigNumber;
    totalReward_d18: BigNumber;
    timestamp: BigNumber;
    deadline: BigNumber;
    period: string;
    readyToBeClaimed: boolean;
  }[],
  stakingContracts: {
    contractAddress: string,
    rewardsPerDay: string,
    rewardsPerDayPerToken: string,
    maxStakesPerUser_d18: string,
    tokensLocked: string,
    paidOutRewards: string,
    rewardsToBeDistributed: string,
    earlyWithdrawalPenalty_d12: string,
    isLocked: boolean,
    period: string
  }[]
}>());
export const oraoMetaFailed = createAction('[OraoMeta] Failed');

export const oraoClaim = createAction('[Orao] Claim');

export const oraoStake = createAction('[OraoMeta] Stake', props<{
  amount: string,
  period: string
}>());

export const oraoUnstake = createAction('[OraoMeta] Unstake', props<{
  id: string,
  period: string
}>());

export const oraoStakeSuccess = createAction('[OraoMeta] Stake Success');
export const oraoUnstakeSuccess = createAction('[OraoMeta] Stake Success');
