// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  NODE_DEBUG: false,
  // oraoAddress: '0xdbC43Ba45381e02825b14322cDdd15eC4B3164E6', //mainnet address
  // stakingAddresses: [
  //   '0xBEc49fA140aCaA83533fB00A2BB19bDdd0290f25',
  //   '0x46b142DD1E924FAb83eCc3c08e4D46E82f005e0E',
  //   '0x4C2F7092C2aE51D986bEFEe378e50BD4dB99C901',
  // ], //mainnet address
  oraoAddress: '0xf84442c3217Cd7b17da0160CD260059A19100947', //mainnet address
  stakingAddresses: [
    '0xc4c49e310cc68191F5F5B45966269dE4816B309c',
    '0x0DEc80D249B862E40403fd2CA36d82474a1F9F61',
    '0xde9d46017b93A287a6D525B18F2F8836e5ba1117',
  ], //mainnet address
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
