<ng-container
  *ngIf="
    {
      balance: oraoBalance$ | async,
      decimals: oraoDecimals$ | async,
      claimable: oraoClaimable$ | async,
      claimablePerPool: oraoClaimablePerPool$ | async
    } as data;
    else loading
  "
>

  <ng-container
    *ngIf="
      data.balance && data.decimals && data.claimable && data.claimablePerPool;
      else loading
    "
  >
    <div class="tokens-banalnce-wrapper">
      <div class="tokens-balance">
        <div class="row tokens-balance-first-row align-items-center justify-content-between">

          <div>

            <div class="powered_by">
              Powered by
            </div>

          </div>

          <div class="logo-wrapper">
            <img src="https://orao.network/_nuxt/img/logo.8e80d0c.svg" alt="">
          </div>

        </div>

        <div class="powered_by_footer">

          

        </div>

        <div class="balance-wrapper">
          <p>Current Balance</p>
          <p>
            <ng-container *ngIf="data.balance && data.decimals">{{
              data.balance | printToken: data.decimals
              }}</ng-container>
          </p>
        </div>
        <div class="balance-wrapper">
          <p>Claimable Tokens</p>
          <p
            *ngIf="{
        claimable: oraoClaimable$ | async,
        decimals: oraoDecimals$ | async
      } as data"
          >
            <ng-container *ngIf="data.claimable && data.decimals">{{
              data.claimable | printToken: data.decimals
              }}</ng-container>
          </p>
        </div>
       
        <button class="default-button claim_btn" mat-button (click)="claim()">Claim</button>
      </div>
    </div>
  </ng-container>
</ng-container>
<ng-template #loading class="preloader-wrapper">
  <mat-spinner class="preloader-wrapper"></mat-spinner>
</ng-template>
