import { createAction, props } from '@ngrx/store';
import { TokenType, ITokenDetails } from './defi-tokens.reducer';

export const defaultAccountLoad = createAction('[DefaultAccount] Load');
export const defaultAccountSuccess = createAction('[DefaultAccount] Success', props<{ defaultAccount: string }>());
export const defaultAccountFailed = createAction('[DefaultAccount] Failed');

export const erc20MetaLoad = createAction('[ERC20] Load', props<{ address: string }>());
export type TokenMeta<TTokenType extends TokenType = TokenType> = {
  address: string;
  balance: string;
  decimals: string;
  symbol: string;
  tokenType: TTokenType;
  details: ITokenDetails[TTokenType];
};
///Distributive conditional types
type TokenMetaTypesUtils<U> = U extends TokenType ? TokenMeta<U> : never
export type TokenMetaTypes = TokenMetaTypesUtils<TokenType>
export const erc20MetaSuccessFactory = <T extends TokenType>() => createAction('[ERC20] Success', props<TokenMeta<T>>());
export const erc20AssetMetaSuccess = erc20MetaSuccessFactory<'asset'>();
 export const erc20BalanceUpdated = createAction('[ERC20] Balance updated', props<{
   address: string,
   holder: string,
   balance: string
  }>());
export const erc20MetaFailed = createAction('[ERC20] Failed');

export type DefiTokensActions =
(typeof erc20MetaFailed)["type"]
