import { BigNumber } from '@ethersproject/bignumber';
import { Pipe, PipeTransform } from "@angular/core";
import { BigNumber as ethersBigNumber } from "ethers";
import moment from 'moment';

@Pipe({ name: 'printDate' })
export class PrintDatePipe implements PipeTransform {
  transform(value: moment.Moment): string {
    return value.format('DD-MM-YYYY')
  }
}
