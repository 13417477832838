import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { WalletStore } from '@solana/wallet-adapter-angular';
import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import BigNumber from 'bignumber.js';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { filter, first, map, shareReplay, startWith, tap } from 'rxjs/operators';
import { Flight, getProvider } from '../insurance/insurance.component';
import { HttpClient } from '@angular/common/http';

import { Keypair, PublicKey, SystemProgram } from '@solana/web3.js';
import { Program, BN } from "@project-serum/anchor";
import * as anchor from "@project-serum/anchor";
import idl from "../insurance/flight_insurance.json";

const programID = new PublicKey(idl.metadata.address); // todo ag we shouldn't depend on programId form idl
const oracleProgramId = new PublicKey(
  "oraogph9PTJAYMfhpmkxNfG6TSCftK4kQyqaNU5YXao"
); //todo ag to config

@Component({
  selector: 'app-buy-form',
  templateUrl: './buy-form.component.html',
  styleUrls: ['./buy-form.component.scss'],
})
export class BuyFormComponent implements OnInit {
  @Input() availableFlights!: Flight[];

  @Output() refreshData: EventEmitter<{}> =
    new EventEmitter<{}>();

  form!: FormGroup;
  currentBalance!: string;
  loaded = false;
  decimals = '9';
  isLoading: boolean = false;
  informations$!: Observable<{ name: string, value: string }[]>;
  expectedInsuranceReward$!: Observable<string>;
  expectedInsuranceRewardUsd$: BehaviorSubject<string> = new BehaviorSubject("0");
  solUsdPrice$: BehaviorSubject<number> = new BehaviorSubject(0);
  filteredOptions!: Observable<Flight[]>;

  private configSuccess: MatSnackBarConfig = {
    panelClass: ['style-success'],    
  };

  private configError: MatSnackBarConfig = {
    panelClass: ['style-error'],
  };

  constructor(
    private wallet: WalletStore,
    private snackBar: MatSnackBar,
    private httpClient: HttpClient
  ) {
    // togo kill this filthiness with fire...
    document.head.insertAdjacentHTML('beforeend', '<style>.style-success .mat-button span { color: green !important; }</style>' );
  }

  async ngOnInit() {
    //this.httpClient.get<{name: string, price_usd: string}[]>("https://mocki.io/v1/12af0feb-4518-414e-a9b1-9d5297e85af7")
    this.httpClient.get<{name: string, price_usd: string}[]>("https://cryptovisio.com/cl_apis/vest.php?w=top10&key=ds92ncmas02_1adsmdssa0d93k61a32gcfd21")
      .subscribe(res => {
        const priceStr = res.find(x => x.name == "Solana")!.price_usd.substr(1);
        const price = parseFloat(priceStr);
        this.solUsdPrice$.next(price);
      });

    this.form = new FormGroup({
      inputAmount: new FormControl(null, [
        Validators.required,
        Validators.pattern('[+-]?([0-9]*[.,])?[0-9]+'),
        Validators.min(0.000000001)
      ]
        , [
          async (control) => {
            const currentValue = new BigNumber(control.value).multipliedBy(10 ** 9);
            const mostRestricting = [{
              msg: 'Account balance exceeded',
              value: new BigNumber(this.currentBalance)
            }].sort((a, b) => a.value.lt(b.value) ? -1 : 1)[0]

            if (currentValue.gt(mostRestricting.value))
              return { msg: mostRestricting.msg }
            return null;
          }
        ]),
      selectedFlight: new FormControl(
        '',
        Validators.required,
        [async (control) => {
          if (typeof control.value === 'string')
            return { msg: 'Please select valid flight' }
          return null;
        }]
      ),
    });

    this.filteredOptions = this.form.get('selectedFlight')!.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );

    this.informations$ = this.form.valueChanges.pipe(map(x => x.selectedFlight as Flight), startWith(undefined),
      map(x => ([
        {
          name: 'Insurance cost',
          value: `${new BigNumber(x?.value || 0).dividedBy(10 ** 6).multipliedBy(100)}%`,
        }
      ])))
    const wallet = await this.wallet.adapter$.pipe(first()).toPromise()
    const publicKey = await this.wallet.publicKey$.pipe(filter(x => !!x), first()).toPromise()
    if (!wallet)
      return
    const provider = await getProvider(wallet!);
    this.currentBalance = (await provider.connection.getBalance(publicKey!)).toString()
    this.loaded = true

    this.expectedInsuranceReward$ = this.form.valueChanges.pipe(
      map(x => x as { inputAmount: string, selectedFlight: Flight }),
      startWith(this.form.value),
      map((insurance: { inputAmount: string, selectedFlight: Flight }) => {
        return !insurance.selectedFlight ? '0' : new BigNumber(insurance.inputAmount || 0).multipliedBy(10 ** 9).multipliedBy(10 ** 6).dividedBy(insurance.selectedFlight.value || 1).toFixed(0)
      }))

    combineLatest([this.expectedInsuranceReward$, this.solUsdPrice$])     
      .pipe(map(([reward, price]) => (parseFloat(reward) * price).toString()))
      .subscribe(val => this.expectedInsuranceRewardUsd$.next(val));
  }
  public clearSelection() {
    this.form.get('selectedFlight')?.setValue('')
  }

  public getOptionText(option: Flight) {
    return option?.flightId;
  }

  private _filter(value: string | Flight): Flight[] {
    const filterValue = typeof value === 'string' ? value.toLowerCase() : value.flightId.toLowerCase();

    return this.availableFlights.filter(option => option.flightId.toLowerCase().includes(filterValue));
  }

  // Get the PDA that is assigned authority to insuranceProgram account.
  // Which basically means that insuranceProgram wons that pda account
  async getTreasury() {
    const wallet = await this.wallet.adapter$.pipe(first()).toPromise()
    const provider = await getProvider(wallet!);

    const insuranceProgram = new Program(idl as any, programID, provider);

    const [treasuryPda, _treasuryNonce] = await PublicKey.findProgramAddress(
      [Buffer.from(anchor.utils.bytes.utf8.encode("treasury"))],
      insuranceProgram.programId
    );

    console.log(treasuryPda.toString())
    return treasuryPda;
  }

  async buyInsurance() {
    this.isLoading = true;

    const wallet = await this.wallet.adapter$.pipe(first()).toPromise()
    const provider = await getProvider(wallet! as any);
    /* create the program interface combining the idl, program ID, and provider */
    const insuranceProgram = new Program(idl as any, programID, provider);
    try {
      const insuranceData = Keypair.generate();

      const treasuryPda = await this.getTreasury();
      const flightNumber = this.form.get('selectedFlight')?.value.name!
      const insurancePaymentLamports = new BigNumber(this.form.get('inputAmount')?.value).multipliedBy(10 ** 9).toFixed(0);

      const oracleNameBytes = anchor.utils.bytes.utf8.encode(
        `fi.p.${flightNumber}`
      );
      const oraceleNameBytesFixedSize = new Uint8Array(32);
      for (let i = 0; i < oracleNameBytes.byteLength; i++) {
        oraceleNameBytesFixedSize[i] = oracleNameBytes[i];
      }

      const [
        flightInsurancePriceOraclePda,
        _flightInsurancePriceOraclePdaNonce,
      ] = await PublicKey.findProgramAddress(
        [oraceleNameBytesFixedSize],
        oracleProgramId
      );

      const publicKey = (await this.wallet.publicKey$.pipe(filter(x => !!x), first()).toPromise());
      const tx = await insuranceProgram.rpc.buyInsurance(
        new BN(insurancePaymentLamports),
        {
          accounts: {
            insurance: insuranceData.publicKey,
            user: publicKey?.toString()!,
            systemProgram: SystemProgram.programId,
            treasuryPda: treasuryPda,
            flightInsurancePriceOracle: flightInsurancePriceOraclePda,
            oracleProgram: oracleProgramId,
          },
          signers: [insuranceData],
        }
      );

      this.refreshData.next()
      this.currentBalance = (await provider.connection.getBalance(publicKey!)).toString()

      this.snackBar.open('Insurance contract started', 'Success!', this.configSuccess);
    } catch (err) {

      this.snackBar.open('Something went wrong during Insurance Claiming', 'Error!', this.configError);
      console.log("Transaction error: ", err);
    }
    this.isLoading = false
    this.form.reset({
      selectedFlight: ''
    });
  }

  setMaxValue() {
    this.form
      .get('inputAmount')
      ?.setValue(
        new BigNumber(this.currentBalance)
          .div(new BigNumber(10).pow(this.decimals))
          .toString()
      );
  }
}
