<form [formGroup]="form">
  <mat-form-field appearance="fill" class="mat-select">
    <mat-label>Stake duration</mat-label>
    <mat-select name="stakeDuration" formControlName="stakeDuration">
      <mat-option *ngFor="let duration of durationStakes" [value]="duration">
        {{ duration.period }} days
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div
    class="informactions-container"
    *ngIf="informations$ | async as informations"
  >
    <div *ngFor="let info of informations" class="info-box">
      <p class="info-box__value">{{ info.value | printToken: decimals }}</p>
      <p class="info-box__name">{{ info.name }}</p>
    </div>
  </div>

  <div class="input-row">
    <div class="input-container">
      <mat-form-field appearance="standard">
        <mat-label>ORAO Token</mat-label>
        <input
          name="inputAmount"
          formControlName="inputAmount"
          matInput
          placeholder="Enter Amount"
        />

        <mat-hint
          >Balance: {{ currentBalance | printToken: decimals }}
          <ng-container *ngIf="form.invalid">
            <span class="errorMessages"> {{ form.controls.inputAmount.errors?.msg }} </span>
          </ng-container>
        </mat-hint>
      </mat-form-field>
      <button class="max-value" (click)="setMaxValue()">MAX</button>
    </div>

    <div
      *ngIf="expectedRewards$ | async as expectedRewards"
      class="info-box"
      [ngStyle]="{ opacity: !form.valid ? '0.2' : '1' }"
    >
      <p class="info-box__value">
        {{ expectedRewards | printToken: decimals }}
      </p>
      <p class="info-box__name">Expected Rewards</p>
    </div>
  </div>
</form>

<div class="informactions-container" *ngIf="true">
  <!-- <ng-container *ngIf="expectedRewards$ | async as expectedRewards">Expected Rewards: {{ expectedRewards | printToken: decimals}}</ng-container> -->
  <!-- <ng-container *ngIf="maxToStake$ | async as maxToStake">Maximum Available For Stake: {{ maxToStake }}</ng-container> -->
  <!-- <ng-container *ngIf="userPossibleMaxStake$ | async as userPossibleMaxStake">User Possible Stake: {{ userPossibleMaxStake }}</ng-container> -->
  <button
    class="default-button claim_btn"
    mat-button
    (click)="stake()"
    [disabled]="!form.valid"
  >
    Stake
  </button>
</div>
