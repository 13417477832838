<div class="balance-wrapper">
  <ng-container *ngIf="insurances.length">
    <ng-container *ngIf="insurances.length">
      <div class="stake-container">
        <div class="title">
          <p class="name">Flight no.</p>
          <p class="date">Date</p>
          <p class="payment">Payment</p>
          <p class="unstakeButton">Claim</p>
        </div>
        <div *ngFor="let insurance of insurances" class="stake">
          <p class="name">
            {{ insurance.flightId }}/{{ insurance.dayCounter }}
          </p>
          <p class="date">{{ insurance.date | printDate }}</p>
          <p class="payment">{{ insurance.payment | printToken: "9" }}</p>

          <div class="unstakeButton">
            <button
              (click)="!isLoading[insurance.flightNumber] && claimInsurance(insurance)"
              class="default-button"
              [disabled]="!_cancelledFlights[insurance.flightNumber.substr(5)]"
            >

              <div *ngIf="!isLoading[insurance.flightNumber]">{{
                          !_successfulFlights[insurance.flightNumber.substr(5)]
                            ? _cancelledFlights[insurance.flightNumber.substr(5)]
                              ? "Claim"
                              : "Wait " + insuranceInDays(insurance.flightNumber) + "d " + insuranceInHours(insurance.flightNumber) % 24 + "h"
                            : "Flight Took Place"
                        }}</div>
              <div *ngIf="isLoading[insurance.flightNumber]" class="spinner">
                <mat-spinner diameter="16"> </mat-spinner>
              </div>

            </button>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
