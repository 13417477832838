import { LockedStakeDialogComponent } from '../locked-stake-dialog/locked-stake-dialog.component';
import { OraoState, selectOraoMeta } from '../../../orao/store/orao.selectors';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { BigNumber } from '@ethersproject/bignumber';
import { first, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';

interface Stake {
  kek_id: string;
  user: string;
  value_d18: BigNumber;
  totalReward_d18: BigNumber;
  timestamp: BigNumber;
  deadline: BigNumber;
  readyToBeClaimed: boolean;
  period: string;
}

@Component({
  selector: 'app-current-stake',
  templateUrl: './current-stake.component.html',
  styleUrls: ['./current-stake.component.scss'],
})
export class CurrentStakeComponent {
  @Input() stakes: Stake[] = [];
  stakingContracts$ = this.oraoStore.select(selectOraoMeta).pipe(map((x) => x.stakingContracts), map(x => Object.fromEntries(x.map(z => [z.period, z]))));

  @Output() unstakeSubmit: EventEmitter<{ kek_id: string; period: string }> =
    new EventEmitter<{ kek_id: string; period: string }>();

  constructor(
    private oraoStore: Store<OraoState>,
    public dialog: MatDialog
  ) { }

  async unstake(stake: Stake) {
    if (stake.readyToBeClaimed) {

      this.unstakeSubmit.emit(stake);
    } else {
      const stakings = await this.stakingContracts$.pipe(first()).toPromise()
      const penalty = stakings[stake.period].earlyWithdrawalPenalty_d12
      const dialogRef = this.dialog.open(LockedStakeDialogComponent, {
        width: '250px',
        data: { punishment: BigNumber.from(100).sub(BigNumber.from(10).pow(12).sub(penalty).mul(100).div(BigNumber.from(10).pow(12))) }
      });

      dialogRef.afterClosed().subscribe(result => {
        if(result)
          this.unstakeSubmit.emit(stake);
      });
    }
  }
}
