import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface DialogData {
  punishment: string;
}

@Component({
  selector: 'app-locked-stake-dialog',
  templateUrl: './locked-stake-dialog.component.html',
  styleUrls: ['./locked-stake-dialog.component.scss'],
})
export class LockedStakeDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<LockedStakeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}
