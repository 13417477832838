import { Title } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

import { AppState } from 'src/app/defi-tokens/store/defi-tokens.selectors';
import { environment } from '../../../../environments/environment';
import { successFilter } from './../../../defi-tokens/store/defi-tokens.selectors';
import { erc20MetaLoad } from '../../../defi-tokens/store/defi-tokens.actions';
import { selectOraoMeta, OraoState } from '../../../orao/store/orao.selectors';
import { oraoClaim } from '../../../orao/store/orao.actions';
import {
  selectErc20Meta,
  selectDefaultAccount,
} from '../../../defi-tokens/store/defi-tokens.selectors';
import { Web3Service } from '../../../interaction/services/web3/web3.service';


@Component({
  selector: 'app-claim',
  templateUrl: './claim.component.html',
  styleUrls: ['./claim.component.scss']
})
export class ClaimComponent implements OnInit {

  orao$ = this.store.select(
    selectErc20Meta(),
    { address: environment.oraoAddress }
  ).pipe(
    successFilter(),
    map(x => x.result)
  )

  oraoDecimals$ = this.orao$
    .pipe(filter(x => !!x),
      map(x => x!.decimals))
  oraoBalance$ = combineLatest([this.orao$, this.store.select(selectDefaultAccount).pipe(successFilter())]).pipe(
    map(([orao, account]) => orao.balance[account.result])
  )

  oraoClaimable$ = this.oraoStore.select(
    selectOraoMeta
  ).pipe(
    map(x => !x ? '0' : x.claimable)
  )
  oraoClaimablePerPool$ = this.oraoStore.select(
    selectOraoMeta
  ).pipe(
    map(x => x.claimablePerPool)
  )

  constructor(private web3: Web3Service, private store: Store<AppState>, private oraoStore: Store<OraoState>, private titleService: Title) {
    titleService.setTitle('Claim ORAO Tokens')
  }

  async ngOnInit(): Promise<void> {
    this.store.dispatch(erc20MetaLoad({
      address: environment.oraoAddress
    }));

  }

  claim() {
    this.store.dispatch(oraoClaim());
  }

}

async function gen2array<T>(gen: AsyncGenerator<T>): Promise<T[]> {
  const out: T[] = []
  for await (const x of gen) {
    out.push(x)
  }
  return out
}
