import { createReducer, on } from '@ngrx/store';
import { BigNumber } from 'ethers';
import { ResultState } from '../../defi-tokens/store/defi-tokens.selectors';
import { oraoMetaFailed, oraoMetaSuccess } from './orao.actions';

export type OraoMeta = {
  orao: {
    address: string;
  };
  claimable: string,
  claimablePerPool: {
    pool: string,
    claimable: string
  }[],
  stakes: {
    kek_id: string;
    user: string;
    value_d18: BigNumber;
    totalReward_d18: BigNumber;
    timestamp: BigNumber;
    deadline: BigNumber;
    period: string;
    readyToBeClaimed: boolean;
  }[],
  stakingContracts: {
    contractAddress: string,
    rewardsPerDay: string,
    rewardsPerDayPerToken: string,
    maxStakesPerUser_d18: string,
    tokensLocked: string,
    paidOutRewards: string,
    rewardsToBeDistributed: string,
    earlyWithdrawalPenalty_d12: string,
    isLocked: boolean,
    period: string
  }[]
};

export const initialState = {
  state: 'uninitiated'
} as
  ResultState<OraoMeta>

const _oraoMetaReducer = createReducer(
  initialState,
  on(oraoMetaSuccess, (state, action) => {
    const { type, ...meta } = action
    return ({
      ...state,
      orao: {
        result: {
          ...meta
        },
        state: 'success'
      },
      claimable: action.claimable,
      claimablePerPool: action.claimablePerPool,
      stakes: action.stakes,
      stakingContracts: action.stakingContracts,
    })
  }),
  on(oraoMetaFailed, (state, action) => {
    const { type, ...meta } = action
    return ({
      ...state
    })
  })
);

export function oraoMetaReducer(state: Parameters<typeof _oraoMetaReducer>[0], action: Parameters<typeof _oraoMetaReducer>[1]) {
  return _oraoMetaReducer(state, action);
}
