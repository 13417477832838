<ng-container *ngIf="tokenMeta$ | async as tokenMeta">
  <mat-form-field appearance="standard">
    <mat-label>ORAO Token ></mat-label>
    <input
      matInput
      type="text"
      [formControl]="control"
      placeholder="Enter amount"
    />
    <mat-hint *ngIf="tokenBalance$ | async as tokenBalance"
      >Balance: {{ tokenBalance | printToken: tokenMeta.decimals }}</mat-hint
    >
  </mat-form-field>
</ng-container>
