import { createReducer, on } from '@ngrx/store';
import { erc20AssetMetaSuccess, defaultAccountSuccess, erc20BalanceUpdated } from './defi-tokens.actions';
import { ResultState } from './defi-tokens.selectors';

export interface ITokenDetails {
  'asset': {},
}

export type TokenType = 'asset'

export type TokenMeta<TTokenType extends TokenType = TokenType> = {
  decimals: string;
  balance: {
    [holder: string]: string;
  };
  symbol: string;
  type: TTokenType;
  details: ITokenDetails[TTokenType]
};

export const initialState = {} as {
  [address: string]: ResultState<TokenMeta>
}


const _erc20Reducer = createReducer(
  initialState,
  on(erc20AssetMetaSuccess, (state, action) => {
    const erc20ExistingMeta = state[action.address];
    return ({
      ...state,
      [action.address]: {
        result: {
          balance: {
            ...(erc20ExistingMeta && 'result' in erc20ExistingMeta && erc20ExistingMeta.result.balance),

          },
          decimals: action.decimals,
          symbol: action.symbol,
          address: action.address,
          type: action.tokenType,
          details: action.details
        },
        state: 'success'
      }
    })
  }),
  on(erc20BalanceUpdated, (state, action) => {
    const erc20ExistingMeta = state[action.address];
    const result = erc20ExistingMeta && 'result' in erc20ExistingMeta && erc20ExistingMeta.result;
    if(!result)
      return state;
    return ({
      ...state,
      [action.address]: {
        result: {
          ...result,
          balance: {
            ...result.balance,
            [action.holder]: action.balance
          },
        },
        state: 'success'
      }
    })
  })
);

export function erc20Reducer(state: Parameters<typeof _erc20Reducer>[0], action: Parameters<typeof _erc20Reducer>[1]) {
  return _erc20Reducer(state, action);
}



export const web3InitialState = {
  address: {
    state: 'uninitiated'
  }
} as {
  address: ResultState<string>
}


const _web3Reducer = createReducer(
  web3InitialState,
  on(defaultAccountSuccess, (state, action) => {
    return ({
      address: {
        result: action.defaultAccount,
        state: 'success'
      }
    })
  })
);
export function web3Reducer(state: Parameters<typeof _web3Reducer>[0], action: Parameters<typeof _web3Reducer>[1]) {
  return _web3Reducer(state, action);
}
