import { DefiTokensModule } from './../defi-tokens/defi-tokens.module';
import { WalletStore, ConnectionStore, CONNECTION_CONFIG, WALLET_CONFIG, WalletConfig } from '@solana/wallet-adapter-angular';
import { PhantomWalletAdapter } from '@solana/wallet-adapter-phantom';
import { MatDialogModule } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { InsuranceComponent } from './components/insurance/insurance.component';
import { InteractionModule } from '../interaction/interaction.module';
import { ConnectionConfig } from '@solana/web3.js';

import {
  getLedgerWallet,
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletExtensionWallet,
  getSolletWallet,
  getTorusWallet,
} from '@solana/wallet-adapter-wallets';
import { BuyFormComponent } from './components/buy-form/buy-form.component';
import { CurrentInsurancesComponent } from './components/current-insurances/current-insurances.component';
import { PrintDatePipe } from './pipes/print-date.pipe';
import {MatSnackBarModule} from '@angular/material/snack-bar';

@NgModule({
  declarations: [
    InsuranceComponent,BuyFormComponent,CurrentInsurancesComponent, PrintDatePipe
  ],
  providers: [
    { provide: CONNECTION_CONFIG, useValue: {

    } as ConnectionConfig},
    { provide: WALLET_CONFIG, useValue: {
      autoConnect: true,
      wallets: [
        getPhantomWallet()
      ]
    } as WalletConfig},
    WalletStore,
    ConnectionStore,
  ],
  exports: [InsuranceComponent, BuyFormComponent],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    InteractionModule,
    DefiTokensModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatFormFieldModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatTabsModule,
    MatSelectModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatSnackBarModule
  ],
})
export class InsurancesModule {}
