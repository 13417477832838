import { createSelector } from '@ngrx/store';
import { Observable, pipe } from 'rxjs';
import { filter } from 'rxjs/operators';

export type IsLoadingState = {
  state: 'loading';
};

export type SuccessfulLoadState<T extends Object> = {
  result: T;
  state: 'success';
};

export type ErrorState = {
  error: string | null;
  state: 'error'
};

export type NotInitiatedState = {
  state: 'uninitiated'
};

export type ResultState<T extends Object> = ErrorState | IsLoadingState | SuccessfulLoadState<T> | NotInitiatedState


export interface AppState {
  erc20: {
    [address: string]: ResultState<{
      decimals: string,
      balance: {
        [holder: string]: string
      },
      symbol: string,
      address: string
    }>
  },
  defaultAccount: {
    address: ResultState<string>
  }
}

export const selectErc20Metas = (state: AppState, props: { address: string }) => {
  return state.erc20
};

export const selectErc20Meta = () => createSelector(
  selectErc20Metas,
  (state, props) => state[props.address]
);

export const selectDefaultAccount = (state: AppState) => {
  return state.defaultAccount.address
};

export const successSelector = function <
  T
>(x: ResultState<T>): x is SuccessfulLoadState<T>  {
  return x && x.state === 'success';
}

export const successFilter = <
  T
>() => pipe<Observable<ResultState<T>>, Observable<SuccessfulLoadState<T>>>(
  filter(successSelector)
)

