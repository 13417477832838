import { WalletAdapter } from '@solana/wallet-adapter-base';
import { WalletStore } from '@solana/wallet-adapter-angular';
import { Component, DebugEventListener, OnInit } from '@angular/core';
import { filter, first, tap } from 'rxjs/operators';
import { WalletName } from '@solana/wallet-adapter-wallets';
import { Connection, PublicKey, SystemProgram } from '@solana/web3.js';
import { Program, Provider } from "@project-serum/anchor";
import * as anchor from "@project-serum/anchor";
import idl from "./flight_insurance.json";
import moment from 'moment'
import BufferJs from 'buffer'
import { publicKey, bool, u8, u16, u32, u64, u128, array, struct, rustEnum, Layout, option } from "@project-serum/borsh";
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
const { u256, union } = require("buffer-layout");
import { DeviceDetectorService } from 'ngx-device-detector';

export type Insurance = {
  publicKey: PublicKey;
  buyer: PublicKey;
  flightNumber: string;
  payment: string;
  rate: number;
  flightId: string;
  dayCounter: string;
  date: moment.Moment;
};
export type Flight = {
  owner: string;
  name: string;
  updatedAt: string;
  value: string;
  flightId: string;
  dayCounter: string;
  date: moment.Moment;
};

function rustEnumFixed<E>(variants: Layout<E>[], defaultLayout: Layout<E> | undefined, property: string) {
  const unionLayout = union(u8(), defaultLayout, property);
  variants.forEach((variant, index) => unionLayout.addVariant(index, variant, property));
  return unionLayout;
}
export const FLIGHT_FEED_LAYOUT = struct([
  publicKey("owner"),
  array(u8(), 32, "name"),
  u64("updated_at"),
  option(rustEnum([bool('Boolean'), u8('U8'), u16('U16'), u32('U32'), u64('U64'), u128('U128')]), 'value'),
]);
function flightFrom(buffer: Uint8Array) {
  const flightData = FLIGHT_FEED_LAYOUT.decode(BufferJs.Buffer.from(buffer));
  const flightNumber = anchor.utils.bytes.utf8
    .decode(Buffer.from(flightData.name.filter((x: number) => x !== 0)))
    .substr(5);
  const [flightId, date, counter] = flightNumber.split('.')
  const momentDate = moment(date, 'YYYYMMDD');
  return {
    owner: flightData.owner.toString() as string,
    updatedAt: flightData.updated_at.toString() as string,
    value: (Object.values(flightData.value)[0] as number).toString() as string,
    name: flightNumber,
    flightId: `${flightId}/${counter} ${momentDate.format('DD/MM/YYYY')}`,
    dayCounter: counter,
    date: momentDate,
  } as Flight;
}

export async function getProvider(wallet: WalletAdapter) {
  if (!wallet) throw new Error();
  /* create the provider and return it to the caller */
  /* network set to local network for now */

  // const network = "http://127.0.0.1:8899";
  const network = "https://api.devnet.solana.com";
  const connection = new Connection(network, {});

  const provider = new Provider(connection, wallet as any, {});
  return provider;
}

const programID = new PublicKey(idl.metadata.address); // todo ag we shouldn't depend on programId form idl
const oracleProgramId = new PublicKey(
  "oraogph9PTJAYMfhpmkxNfG6TSCftK4kQyqaNU5YXao"
); //todo ag to config

@Component({
  selector: 'app-insurance',
  templateUrl: './insurance.component.html',
  styleUrls: ['./insurance.component.scss'],
})
export class InsuranceComponent implements OnInit {
  userInsurances: Insurance[] = [];
  availableFlights: Flight[] = [];
  successfulFlights: Flight[] = [];
  cancelledFlights: Flight[] = [];
  loaded = false;
  isLoadingFlight: {[key: string]: boolean} = {};
  constructor(
    private wallet: WalletStore,
    private titleService: Title,
    private snackBar: MatSnackBar,
    private deviceService: DeviceDetectorService
  ) {
    this.titleService.setTitle('ORAO Insurance Platform');
  }

  async ngOnInit(): Promise<void> {
    
    if(!this.deviceService.isDesktop()){
      this.snackBar.open('Currently mobile devides are not supported. Please use a desktop.', 'Error!');
      return;
    }

    this.wallet.selectWallet(WalletName.Phantom)

    let foundWallet = false;
    setTimeout(() => {
      if(!foundWallet){
        this.snackBar.open('Please install Phantom Wallet', 'Error!');
      }
    }, 3 * 1000);
    
    await this.wallet.ready$.pipe(filter(x => x), first()).toPromise()

    foundWallet = true;

    const value = await this.wallet.connect().toPromise()
    const wallet = await this.wallet.adapter$.pipe(first()).toPromise() as WalletAdapter
    const publicKey = await this.wallet.publicKey$.pipe(filter(x => !!x), first()).toPromise()
    if (!wallet) {
      return
    }
    const provider = await getProvider(wallet)

    const { successful, cancelled } = await this.getPastFlights(provider);
    this.cancelledFlights = cancelled;
    this.successfulFlights = successful;

    this.availableFlights = (await this.getAvailableFlights(provider))
      .filter(x => ![...this.cancelledFlights, ...this.successfulFlights].find(z => z.name === x.name));


    const insuranceProgram = new Program(idl as any, programID, provider);
    this.userInsurances = await this.fetchInsurances(insuranceProgram, provider.wallet.publicKey)
    this.loaded = true;
  }

  async fetchInsurances(
    program: Program<any>,
    owner: PublicKey
  ): Promise<Insurance[]> {
    const allInsurances = await program.account.insurance.all([
      {
        memcmp: {
          offset: 8,
          bytes: owner.toBase58(),
        },
      },
    ]);
    const insurances: Insurance[] = allInsurances.map((x) => {
      const flightNumber = anchor.utils.bytes.utf8.decode(
        Buffer.from(x.account.flightNumber).filter((x: number) => x !== 0)
      );
      const [_1, _2, flightId, date, counter] = flightNumber.split('.')
      return ({
        publicKey: x.publicKey,
        buyer: x.account.buyer,
        flightNumber: flightNumber,
        flightId: flightId,
        dayCounter: counter,
        date: moment(date, 'YYYYMMDD'),
        rate: x.account.insuranceRateD6 / 1e6, //todo ag this value is not parsed well
        payment: x.account.insurancePaymentLamports.toString(), //todo ag this value is not parsed well
      })
    });

    return insurances;
  }

  private async getPastFlights(provider: Provider) {
    const newLocal = anchor.utils.bytes.bs58.encode(
      anchor.utils.bytes.utf8.encode("fi.c.")
    );
    const flights = await provider.connection.getProgramAccounts(
      oracleProgramId,
      {
        filters: [
          {
            memcmp: {
              offset: 32,
              bytes: newLocal,
            },
          },
        ],
      }
    );

    const formattedFlights = flights.map((x) => flightFrom(x.account.data));
    return {
      successful: formattedFlights.filter(x => x.value === '0'),
      cancelled: formattedFlights.filter(x => x.value === '1')
    }
  }

  private async getAvailableFlights(provider: Provider) {
    const accountPrefix = anchor.utils.bytes.bs58.encode(
      anchor.utils.bytes.utf8.encode("fi.p.")
    );
    const flights = await provider.connection.getProgramAccounts(
      oracleProgramId,
      {
        filters: [
          {
            memcmp: {
              offset: 32,
              bytes: accountPrefix,
            },
          },
        ],
      }
    );
    const formattedFlights = flights.map((x) => flightFrom(x.account.data));
    return formattedFlights
  }


  // Get the PDA that is assigned authority to insuranceProgram account.
  // Which basically means that insuranceProgram wons that pda account
  async getTreasury() {
    const wallet = await this.wallet.adapter$.pipe(first()).toPromise()
    const provider = await getProvider(wallet!);

    const insuranceProgram = new Program(idl as any, programID, provider);

    const [treasuryPda, _treasuryNonce] = await PublicKey.findProgramAddress(
      [Buffer.from(anchor.utils.bytes.utf8.encode("treasury"))],
      insuranceProgram.programId
    );
    return treasuryPda;
  }

  async claimInsurance(insurance: Insurance) {
    try {
      this.isLoadingFlight[insurance.flightNumber] = true;
      const wallet = await this.wallet.adapter$.pipe(first()).toPromise()
      const provider = await getProvider(wallet!);

      const insuranceProgram = new Program(idl as any, programID, provider);

      const treasuryPda = await this.getTreasury();

      console.log("Before claim insurance");

      const tx = await insuranceProgram.rpc.claimInsurance({
        accounts: {
          insurance: insurance.publicKey,
          user: (await this.wallet.publicKey$.pipe(filter(x => !!x), first()).toPromise())?.toString()!,
          systemProgram: SystemProgram.programId,
          treasuryPda: treasuryPda,
        },
        signers: [],
      });

      this.snackBar.open('Insurance successfully claimed', 'Success!');
      await this.refreshData()
    } catch {

      this.snackBar.open('Something went wrong during Insurance Claiming', 'Error!');
    }
    this.isLoadingFlight[insurance.flightNumber] = false;
  }

  async refreshData() {

    const wallet = await this.wallet.adapter$.pipe(first()).toPromise() as WalletAdapter
    const provider = await getProvider(wallet)
    const insuranceProgram = new Program(idl as any, programID, provider);
    this.userInsurances = await this.fetchInsurances(insuranceProgram, provider.wallet.publicKey)
  }

}
