import { InsurancesModule } from './insurances/insurances.module';
import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { EffectsModule } from '@ngrx/effects';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ClaimingModule } from './claiming/claiming.module';
import { DefiTokensModule } from './defi-tokens/defi-tokens.module';
import { InteractionModule } from './interaction/interaction.module';
import { OraoModule } from './orao/orao.module';
import { StakingModule } from './staking/staking.module';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [AppComponent],
  imports: [
    ClaimingModule,
    StakingModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    InteractionModule,
    DefiTokensModule,
    MatSidenavModule,
    MatListModule,
    MatButtonModule,
    OraoModule,
    EffectsModule.forRoot([]),
    InsurancesModule,
    HttpClientModule
  ],
  providers: [Title],
  bootstrap: [AppComponent],
})
export class AppModule {}
