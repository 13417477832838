import { Injectable } from '@angular/core';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subject } from 'rxjs';
import { first, map, shareReplay } from 'rxjs/operators';
import { providers } from 'ethers';
import Web3 from 'web3';
import Web3Modal from 'web3modal';
import { ResultState } from 'src/app/defi-tokens/store/defi-tokens.selectors';
import { defaultAccountSuccess } from 'src/app/defi-tokens/store/defi-tokens.actions';
import { successFilter } from './../../../defi-tokens/store/defi-tokens.selectors';

import { WalletName } from '@solana/wallet-adapter-wallets';

@Injectable({
  providedIn: 'root'
})
export class Web3Service {

  web3State = new BehaviorSubject<ResultState<providers.Web3Provider>>({
    state: 'uninitiated'
  });
  web3 = this.web3State.pipe(
    successFilter(),
    map(x => x.result),
    first(),
    shareReplay()
  )
  provider!: providers.Web3Provider;
  accounts: any;
  uDonate: any;
  web3Modal

  private accountStatusSource = new Subject<any>();
  accountStatus$ = this.accountStatusSource.asObservable();
  private newOrganization = new Subject<any>();
  newOrganization$ = this.newOrganization.asObservable();

  constructor(private web3Store: Store<ResultState<Web3>>) {
    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider, // required
        options: {
          infuraId: "INFURA_ID" // required
        }
      }
    };

    this.web3Modal = new Web3Modal({
      //network: "mainnet", // optional
      cacheProvider: true, // optional
      providerOptions, // required
      theme: {
        background: "rgb(39, 49, 56)",
        main: "rgb(199, 199, 199)",
        secondary: "rgb(136, 136, 136)",
        border: "rgba(195, 195, 195, 0.14)",
        hover: "rgb(16, 26, 32)"
      }
    });
  }

  async connectAccount() {
    const web3 = (await this.web3State.pipe(first()).toPromise());
    if(web3.state === 'uninitiated') {
      this.web3State.next({
        state: 'loading'
      })
      //this.web3Modal.clearCachedProvider();

      this.provider = new providers.Web3Provider(await this.web3Modal.connect()); // set provider
      this.web3State.next({
        result: this.provider,
        state: 'success'
      })
      this.web3Store.dispatch(defaultAccountSuccess({
        defaultAccount: await this.provider.getSigner().getAddress()
      }));
      (this as any).test = '123'
    }

    // this.web3js = new Web3(this.provider); // create web3 instance
    // this.accounts = await this.web3js.eth.getAccounts();
    // this.accountStatusSource.next(this.accounts)
  }

}
