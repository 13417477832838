import { Pipe, PipeTransform } from '@angular/core';
import BigNumber from 'bignumber.js';
import { BigNumber as ethersBigNumber } from '@ethersproject/bignumber';

@Pipe({ name: 'printToken' })
export class PrintTokenPipe implements PipeTransform {
  transform(value: string | ethersBigNumber, decimals: string): string {
    const amount = new BigNumber(typeof value === 'string' ? value : value.toString())
      .div(new BigNumber(10).pow(decimals));
    if(amount.gte(100))
      return amount
        .toFixed(0);
    else if(amount.gte(0.01))
      return amount
        .toFixed(2);
    return amount
      .toString();
  }
}
