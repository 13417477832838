<ng-container *ngIf="loaded; else loading">
  <div class="tokens-banalnce-wrapper">
    <div class="tokens-balance">
      <div class="row align-items-center justify-content-between orao-header">
        <div>
          <div class="powered_by">Powered by</div>
        </div>

        <div class="logo-wrapper">
          <img src="https://orao.network/_nuxt/img/logo.8e80d0c.svg" alt="" />
        </div>
      </div>
      <mat-tab-group animationDuration="0ms" [selectedIndex]="0">
        <mat-tab label="Buy Insurance">
          <ng-container *ngTemplateOutlet="buyInsurance"></ng-container>
        </mat-tab>
        <mat-tab label="My Insurances">
          <ng-container *ngTemplateOutlet="myInsurances"></ng-container>
        </mat-tab>
      </mat-tab-group>

      <ng-template #buyInsurance>
        <ng-container *ngIf="availableFlights.length > 0; else noFlights">
          <app-buy-form
            *ngIf="availableFlights?.length"
            [availableFlights]="availableFlights"
            (refreshData)="refreshData()"
          ></app-buy-form>
        </ng-container>
        <ng-template #noFlights class="preloader-wrapper">
          No flights available at this time
        </ng-template>
      </ng-template>

      <ng-template #myInsurances>
        <ng-container *ngIf="userInsurances.length > 0; else noInsurances">
          <app-current-insurances
            *ngIf="userInsurances"
            (claimRewards)="claimInsurance($event)"
            [insurances]="userInsurances"
            [successfulFlights]="successfulFlights"
            [cancelledFlights]="cancelledFlights"
            [isLoading]="isLoadingFlight"
          ></app-current-insurances>
        </ng-container>
        <ng-template #noInsurances class="preloader-wrapper">
          No insurances bought
        </ng-template>
      </ng-template>
    </div>
  </div>
</ng-container>
<ng-template #loading class="preloader-wrapper">
  <mat-spinner class="preloader-wrapper"></mat-spinner>
</ng-template>
