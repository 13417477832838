import { InsuranceComponent } from './insurances/components/insurance/insurance.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClaimComponent } from './claiming/components/claim/claim.component';
import { StakingComponent } from './staking/components/stake/staking.component';

const routes: Routes = [{
  path: 'staking',
  component: StakingComponent
},{
  path: 'claim',
  component: ClaimComponent
},{
  path: 'insurance',
  component: InsuranceComponent
}, {
  path: '**',
  redirectTo: 'insurance'
}];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
