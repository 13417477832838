import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { OraoToken } from '@orao/orao-token/typings/OraoToken.d';
import OraoTokenAbi from '@orao/orao-token/abis/OraoToken.json';
import { OraoStaking } from '@orao/orao-token/typings/OraoStaking.d';
import OraoStakingAbi from '@orao/orao-token/abis/OraoStaking.json';
import { BigNumber, ethers } from 'ethers';
import { of } from 'rxjs';
import { map, mergeMap, catchError, withLatestFrom } from 'rxjs/operators';
import range from 'lodash.range';

import { AppState } from 'src/app/defi-tokens/store/defi-tokens.selectors';
import { environment } from '../../../environments/environment';
import { erc20MetaLoad } from './../../defi-tokens/store/defi-tokens.actions';
import {
  oraoMetaLoad,
  oraoMetaFailed,
  oraoMetaSuccess,
  oraoClaim,
  oraoStake,
  oraoStakeSuccess,
  oraoUnstake,
  oraoUnstakeSuccess,
} from './orao.actions';
import { selectOraoMeta, OraoState } from './orao.selectors';
import { Web3Service } from '../../interaction/services/web3/web3.service';

@Injectable()
export class OraoEffects {
  loadErc20$ = createEffect(() =>
    this.actions$.pipe(
      ofType(oraoMetaLoad),
      mergeMap(async (action) => {
        const web3 = await this.webService.web3.toPromise();
        return web3;
      }),
      map(
        (web3) => {
          return {
            orao: (new ethers.Contract(
              environment.oraoAddress,
              OraoTokenAbi,
              web3.getSigner()
            ) as unknown) as OraoToken,
            stakings: environment.stakingAddresses.map(address => (new ethers.Contract(
              address,
              OraoStakingAbi,
              web3.getSigner()
            ) as unknown) as OraoStaking)
          }
        }
      ),
      mergeMap(async ({ orao, stakings }) => {
        const claimablePerPool = await getClaimableTokens(orao);
        const stakingContracts = await getStakingContracts(stakings)
        const currentStakes = await getCurrentStakes(stakingContracts)
        return oraoMetaSuccess({
          orao: { address: await orao.address },
          claimablePerPool: claimablePerPool.map(x => ({
            pool: x.pool,
            claimable: x.claimable.toString()
          })),
          claimable: claimablePerPool.reduce((prev, curr) => prev.add(curr.claimable), BigNumber.from(0)).toString(),
          stakes: currentStakes,
          stakingContracts: await Promise.all(stakingContracts.map(async x => ({
            period: x.period,
            rewardsPerDay: await (await x.contract.estimatedTotalRewardsPerDay()).toString(),
            rewardsPerDayPerToken: await (await x.contract.reward_perDay_perToken_d18()).toString(),
            maxStakesPerUser_d18: await (await x.contract.maxStakesPerUser_d18()).toString(),
            tokensLocked: await (await x.contract.totalStakesLocked_d18()).toString(),
            paidOutRewards: await (await x.contract.paidOutRewards_d18()).toString(),
            rewardsToBeDistributed: await (await x.contract.availableRewards()).toString(),
            earlyWithdrawalPenalty_d12: await (await x.contract.earlyWithdrawalPenalty_d12()).toString(),
            isLocked: await (await x.contract.isLocked()),
            contractAddress: x.contract.address
          })))
        })
      }),
      catchError((e) => {
        return of(oraoMetaFailed())
      })
    )

  );

  claimToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(oraoClaim),


      mergeMap(async (action) => {
        const web3 = await this.webService.web3.toPromise();
        return web3;
      }),
      map(
        (web3) => {
          return (new ethers.Contract(
            environment.oraoAddress,
            OraoTokenAbi,
            web3.getSigner()
          ) as unknown) as OraoToken
        }
      ),
      mergeMap(async contract => {
        try {
          await (await contract.claim()).wait();
          return erc20MetaLoad({
            address: contract.address
          });
        }
        catch {
          return oraoMetaFailed()
        }
      }),
      mergeMap(x => of(x, oraoMetaLoad())),
      catchError((e) => {
        return of(oraoMetaFailed())
      })
    )

  );

  stake$ = createEffect(() =>
    this.actions$.pipe(
      ofType(oraoStake), withLatestFrom(this.oraoStore.select(selectOraoMeta)),
      mergeMap(async ([action, oraoMeta]) => {
        const web3 = await this.webService.web3.toPromise();
        return { web3, action, oraoMeta };
      }),
      map(
        ({ web3, action, oraoMeta }) => {
          return {
            action,
            orao: (new ethers.Contract(
              environment.oraoAddress,
              OraoTokenAbi,
              web3.getSigner()
            ) as unknown) as OraoToken,
            staking: (new ethers.Contract(
              oraoMeta.stakingContracts.find(x => x.period === action.period)!.contractAddress,
              OraoStakingAbi,
              web3.getSigner()
            ) as unknown) as OraoStaking
          }
        }
      ),
      mergeMap(async ({ action, orao, staking }) => {
        try {
          await (await orao.approve(staking.address, action.amount)).wait()
          await (await staking.stake(action.amount)).wait();
          return erc20MetaLoad({
            address: orao.address
          });
        }
        catch {
          return oraoMetaFailed()
        }
      }),
      mergeMap(x => of(x, oraoMetaLoad(), oraoStakeSuccess())),
      catchError((e) => {
        return of(oraoMetaFailed())
      })
    )

  );
  unstake$ = createEffect(() =>
    this.actions$.pipe(
      ofType(oraoUnstake), withLatestFrom(this.oraoStore.select(selectOraoMeta)),
      mergeMap(async ([action, oraoMeta]) => {
        const web3 = await this.webService.web3.toPromise();
        return { web3, action, oraoMeta };
      }),

      map(
        ({ web3, action, oraoMeta }) => {
          return {
            action,
            orao: (new ethers.Contract(
              environment.oraoAddress,
              OraoTokenAbi,
              web3.getSigner()
            ) as unknown) as OraoToken,
            staking: (new ethers.Contract(
              oraoMeta.stakingContracts.find(x => x.period === action.period)!.contractAddress,
              OraoStakingAbi,
              web3.getSigner()
            ) as unknown) as OraoStaking
          }
        }
      ),
      mergeMap(async ({ action, orao, staking }) => {
        try {
          await (await staking.unstake(action.id)).wait();
          return erc20MetaLoad({
            address: orao.address
          });
        }
        catch {
          return oraoMetaFailed()
        }
      }),
      mergeMap(x => of(x, oraoMetaLoad(), oraoUnstakeSuccess())),
      catchError((e) => {
        return of(oraoMetaFailed())
      })
    )

  );
  constructor(
    private actions$: Actions,
    private webService: Web3Service, private store: Store<AppState>, private oraoStore: Store<OraoState>
  ) { }
}

const knownPools = ['preseed_distribution', 'seed_distribution', 'strategic_distribution', 'private_distribution', 'presale_distribution_a', 'presale_distribution_b', 'presale_distribution_c', 'presale_distribution_d', 'presale_distribution_e', 'presale_distribution_f', 'validator_rewards_distribution', 'customer_incentives_distribution', 'reserve_distribution', 'liquidity_distribution', 'advisors_partners_distribution', 'team_distribution']

async function getClaimableTokens(contract: OraoToken) {
  const deploymentTimestamp = await (await contract.deploymentTimestamp()).toNumber();
  const isPaused = await contract.isPaused()
  const pauseTimestamp = await (await contract.pauseTimestamp()).toNumber();
  const currentTimestamp = await (await contract.provider.getBlock('latest')).timestamp
  const timestampDifference = (isPaused ? pauseTimestamp : currentTimestamp) - deploymentTimestamp;

  const numberOfIntervals = Math.floor(timestampDifference / (3600 * 24 * 30)) + 1
  let sumDebts = new Array<{ pool: string, claimable: BigNumber }>();
  for (const pool of knownPools) {
    const poolInfo = await contract.poolInfos(pool);
    const poolDistribution = await contract.getPoolDistribution(pool);
    const userInfo = await contract.userInfos(pool, await contract.signer.getAddress())
    if (userInfo.shareWeight.gt(0)) {
      const lastInterval = userInfo.nextClaimingPeriod
      const accumulatedTotalSinceLastTimestamp = range(lastInterval.toNumber(), numberOfIntervals).reduce((prev, curr) => prev.add(poolDistribution[curr]), BigNumber.from(0))
      const accumulatedSinceLastTimestamp = accumulatedTotalSinceLastTimestamp.mul(userInfo.shareWeight).div(poolInfo.totalSharesWeight);
      sumDebts.push({
        pool: pool,
        claimable: (userInfo.rewardDebt).add(accumulatedSinceLastTimestamp)
      });
    }
  }
  return sumDebts;
}

//change to multicaller
async function* getUserStakes(contract: OraoStaking, user: string) {
  for (let i = 0; ; i++) {
    try {
      const stakeId = await contract.userStakesIds(user, i)
      const stakes = await contract.stakesByIds(stakeId);
      const deadline = stakes.timestamp.add(await (await contract.lockPeriod_days()).mul(60 * 60 * 24));
      yield {
        ...stakes,
        deadline,
        readyToBeClaimed: deadline.lt(Math.floor(Date.now()/1000))
      }
    }
    catch {
      return
    }
  }
}

async function gen2array<T>(gen: AsyncGenerator<T>): Promise<T[]> {
  const out: T[] = []
  for await (const x of gen) {
    out.push(x)
  }
  return out
}


async function getCurrentStakes(contracts: { contract: OraoStaking, period: string }[]) {
  const stakes = await Promise.all(contracts.map(async ({ contract, period }) => {
    const userAddress = await contract.signer.getAddress();
    const stakes = await gen2array(getUserStakes(contract, userAddress))
    return stakes.map(x => ({ ...x, period }))
  }))
  return stakes.flat()
}


async function getStakingContracts(contracts: OraoStaking[]) {
  return (await Promise.all(contracts.map(async contract => ({
    contract,
    period: await (await contract.lockPeriod_days()).toString()
  }))))
}
