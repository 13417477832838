<mat-sidenav-container class="page-contentcontainer">
  <mat-sidenav mode="side" closed
    ><mat-list>
      <mat-list-item>
        <a mat-button [routerLink]="['claim']" routerLinkActive="mat-accent">
          Claim
        </a>
      </mat-list-item>
    </mat-list></mat-sidenav
  >
  <mat-sidenav-content><router-outlet></router-outlet></mat-sidenav-content>
</mat-sidenav-container>
