import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import BigNumber from 'bignumber.js';
import { Observable } from 'rxjs';
import { first, map, startWith} from 'rxjs/operators';
import { OraoState, selectOraoMeta } from './../../../orao/store/orao.selectors';

type StakeInfo = {
  contractAddress: string;
  rewardsPerDay: string;
  rewardsPerDayPerToken: string;
  maxStakesPerUser_d18: string;
  tokensLocked: string;
  paidOutRewards: string;
  rewardsToBeDistributed: string;
  period: string;
};

@Component({
  selector: 'app-staking-form',
  templateUrl: './staking-form.component.html',
  styleUrls: ['./staking-form.component.scss'],
})
export class StakingFormComponent implements OnInit {
  @Output() submitStake: EventEmitter<{ period: string, amount: string }> = new EventEmitter<{ period: string, amount: string }>();
  @Input() address: string = '';
  @Input() currentBalance: string = '';
  @Input() decimals: string = '';
  @Input() set durationStakes(value: StakeInfo[]) {
    this._durationStakes = value;
    if (this.form) {
      const stakeDuration = this.form.get('stakeDuration')?.value;
      this.form.get('stakeDuration')?.setValue(value.find(x => x.period === stakeDuration.period))
    }
  };

  form!: FormGroup;
  stakes$ = this.oraoStore.select(selectOraoMeta).pipe(map((x) => x.stakes));
  informations$!: Observable<{ name: string, value: string }[]>;
  expectedRewards$!: Observable<string>;
  maxToStake$!: Observable<string>;
  userPossibleMaxStake$!: Observable<string>;
  disableBtn = false;
  private _durationStakes: StakeInfo[] = []

  get durationStakes() {
    return this._durationStakes;
  };
  get inputAmount(){
    return this.form.get('inputAmount')?.value;
  }

  constructor(
    private oraoStore: Store<OraoState>
  ) { }
  ngOnInit() {
    this.form = new FormGroup({
      inputAmount: new FormControl(null, [
        Validators.required,
        Validators.pattern('[+-]?([0-9]*[.,])?[0-9]+'),
      ], [
        async (control) => {
          const currentValue = new BigNumber(control.value);
          const stake = this.form.value.stakeDuration
          const maxToStake = new BigNumber(stake.rewardsToBeDistributed).dividedBy(stake.rewardsPerDayPerToken).dividedBy(stake.period).toString()
          const currentStakes = await this.stakes$.pipe(first()).toPromise()
          const currentlyStaked = currentStakes.filter(x => x.period === stake.period).reduce((prev, curr) => prev.plus(curr.value_d18.toString()), new BigNumber(0))
          const userPossibleNewStakes = new BigNumber(stake.maxStakesPerUser_d18).minus(currentlyStaked).div(1e18)
          const mostRestricting = [{
            msg: 'Account balance exceeded',
            value: new BigNumber(this.currentBalance)
          },{
            msg: 'Possible staked tokens in pool exceeded',
            value: new BigNumber(maxToStake)
          },{
            msg: 'Possible user staked tokens in pool exceeded',
            value: userPossibleNewStakes
          }].sort((a, b) => a.value.lt(b.value) ? -1 : 1)[0]

          if (currentValue.gt(mostRestricting.value))
            return { msg: mostRestricting.msg }
          return null;
        }
      ]),
      stakeDuration: new FormControl(
        this.durationStakes[0],
        Validators.required
      ),
    });

    this.informations$ = this.form.valueChanges.pipe(map(x => x.stakeDuration as StakeInfo), startWith(this.durationStakes[0]),
      map(x => ([
        {
          name: 'Tokens Locked',
          value: x.tokensLocked,
        },
        {
          name: 'Rewards Left',
          value: x.rewardsToBeDistributed,
        },
        {
          name: 'Tokens Locked',
          value: x.tokensLocked,
        },
        {
          name: 'Paid Out Rewards',
          value: x.paidOutRewards,
        },
      ])))
    this.expectedRewards$ = this.form.valueChanges.pipe(
      map(x => x as { inputAmount: string, stakeDuration: StakeInfo }),
      startWith(this.form.value),
      map((stake: { inputAmount: string, stakeDuration: StakeInfo }) => {
        return new BigNumber(stake.inputAmount || 0).multipliedBy(stake.stakeDuration.rewardsPerDayPerToken).multipliedBy(stake.stakeDuration.period).toFixed(0)
      }))
  }

  stake() {
    const stakeValue = this.form.get('inputAmount')?.value;
    const stakeDuration = this.form.get('stakeDuration')?.value;
    this.submitStake.emit(
      {
        period: stakeDuration.period,
        amount:
          new BigNumber(stakeValue)
            .multipliedBy(new BigNumber(10).pow(this.decimals))
            .toFixed(0)
      }
    );
    this.form.reset({ stakeDuration: this.durationStakes[0] });
  }

  setMaxValue() {
    this.form
      .get('inputAmount')
      ?.setValue(
        new BigNumber(this.currentBalance)
          .div(new BigNumber(10).pow(this.decimals))
          .toFixed(0)
      );
  }
}
