import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { WETH9Mock } from '@orao/sushiswap/typings/WETH9Mock';
import WETH9MockAbi from '@orao/sushiswap/abis/WETH9Mock.json';
import { of, from, merge, combineLatest } from 'rxjs';
import {
  map,
  mergeMap,
  catchError,
  filter,
  tap,
  switchMap,
  first,
} from 'rxjs/operators';
import { ethers } from 'ethers';

import { AppState } from 'src/app/defi-tokens/store/defi-tokens.selectors';
import { DefiTokensModule } from './../defi-tokens.module';
import { selectDefaultAccount, selectErc20Meta } from './defi-tokens.selectors';
import { Web3Service } from './../../interaction/services/web3/web3.service';
import {
  erc20AssetMetaSuccess,
  erc20BalanceUpdated,
  erc20MetaFailed,
  erc20MetaLoad,
  TokenMetaTypes,
} from './defi-tokens.actions';
import { TokenMeta } from './defi-tokens.actions';

@Injectable()
export class DefiTokensEffects {
  loadErc20$ = createEffect(() =>
    this.actions$.pipe(
      ofType(erc20MetaLoad),
      mergeMap((action) => {
        return this.webService.web3.pipe(
          map(
            (web3) =>
              (new ethers.Contract(
                action.address,
                WETH9MockAbi,
                web3.getSigner()
              ) as unknown) as WETH9Mock
          ),
          tap((x) => console.log(x)),
          switchMap((contract) =>
            merge(
              from(
                (async () => {
                  const provider = this.tokenMetaProviders.find(x => x.canHandle(action))
                  if (!provider)
                    throw new Error(`Provider for token ${action.address} not found`)
                  const data = await provider.handle(action.address)
                  switch (data.tokenType) {
                    case 'asset':
                    default:
                      return erc20AssetMetaSuccess(data)
                  }
                })()
              ),
              combineLatest([
                this.store.select(selectDefaultAccount),
                this.store
                  .select(selectErc20Meta(), { address: contract.address })
                  .pipe(
                    filter((x) => x && 'result' in x),
                    first()
                  ),
              ]).pipe(
                filter(([x]) => x && 'result' in x),
                mergeMap(async ([x]) =>
                  erc20BalanceUpdated({
                    address: contract.address,
                    holder: (x && 'result' in x && x.result) || '',
                    balance: (
                      await contract.balanceOf(
                        (x && 'result' in x && x.result) || ''
                      )
                    ).toString(),
                  })
                )
              )
            )
          ),
          catchError(() => of(erc20MetaFailed()))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private webService: Web3Service,
    private store: Store<AppState>,
    @Inject(DEFI_TOKEN_METADATA_PROVIDER) private tokenMetaProviders: Array<IDefiTokenMetaProvider>
  ) { }
}

export interface IDefiTokenMetaProvider {
  canHandle(token: { address: string }): boolean;
  handle(address: string): Promise<TokenMetaTypes>;
}

@Injectable({
  providedIn: DefiTokensModule
})
export class AssetDefiTokenProvider implements IDefiTokenMetaProvider {
  constructor(
    private webService: Web3Service
  ) { }
  canHandle(token: { address: string; }): boolean {
    return true;
  }
  async handle(address: string): Promise<TokenMeta<'asset'>> {
    const web3 = await this.webService.web3.toPromise();
    const contract = (new ethers.Contract(
      address,
      WETH9MockAbi,
      web3.getSigner()
    ) as unknown) as WETH9Mock
    return {
      address: address,
      symbol: await contract.symbol(),
      decimals: await (await contract.decimals()).toString(),
      balance: (
        await contract.balanceOf(
          await contract.signer.getAddress()
        )
      ).toString(),
      tokenType: 'asset',
      details: {}
    }
  }

}
export const DEFI_TOKEN_METADATA_PROVIDER = new InjectionToken<IDefiTokenMetaProvider>('DefiTokenMetaProvider');
