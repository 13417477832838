import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { OraoEffects } from './store/orao.effects';
import { oraoMetaReducer } from './store/orao.reducer';
import { InteractionModule } from '../interaction/interaction.module';



@NgModule({
  declarations: [],
  providers: [
  ],
  imports: [
    InteractionModule,
    EffectsModule.forFeature([OraoEffects]),
    CommonModule, StoreModule.forFeature('orao', oraoMetaReducer)
  ]
})
export class OraoModule { }
