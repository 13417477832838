import { OraoState, selectOraoMeta } from '../../../orao/store/orao.selectors';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { BigNumber } from '@ethersproject/bignumber';
import { first, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { WalletStore, ConnectionStore, CONNECTION_CONFIG, WALLET_CONFIG, WalletConfig } from '@solana/wallet-adapter-angular';
import { Flight, Insurance } from '../insurance/insurance.component';
import { PublicKey } from '@solana/web3.js';
import { debug } from 'console';


@Component({
  selector: 'app-current-insurances',
  templateUrl: './current-insurances.component.html',
  styleUrls: ['./current-insurances.component.scss'],
})
export class CurrentInsurancesComponent {
  @Input() insurances: Insurance[] = [];
  @Input() set successfulFlights(value: Flight[]) {
    this._successfulFlights = Object.fromEntries(value.map(x =>[x.name, x]));
  };
  _successfulFlights: {[key: string]: Flight} = {};
  @Input() set cancelledFlights(value: Flight[]) {
    this._cancelledFlights = Object.fromEntries(value.map(x =>[x.name, x]));
  };
  _cancelledFlights: {[key: string]: Flight} = {};
  // stakingContracts$ = this.oraoStore.select(selectOraoMeta).pipe(map((x) => x.stakingContracts), map(x => Object.fromEntries(x.map(z => [z.period, z]))));
  @Input() isLoading!: {[key: string]: boolean};

  @Output() claimRewards: EventEmitter<Insurance> =
    new EventEmitter<Insurance>();

  constructor(
    private wallet: WalletStore
  ) { }

  public insuranceInHours(flightNumer: string){
    const segments = flightNumer.split(".");
    const dateString = segments[segments.length-2];
    const year = parseInt(dateString.substr(0,4));
    const month = parseInt(dateString.substr(4,2))-1;
    const day = parseInt(dateString.substr(6,2));
    const flightDate = new Date(year, month, day);
    const now = new Date();

    const hours = Math.floor((flightDate.getTime() - now.getTime())/1000/60/60);

    return Math.max(0, hours + 24); // +24 so orao has time to verify
  } 

  public insuranceInDays(flightNumer: string){
    const hours = this.insuranceInHours(flightNumer);
    const fullDays = Math.floor(hours/24);
    return fullDays;
  }

  async claimInsurance(insurance: Insurance) {
    this.claimRewards.emit(insurance);
  }
}
