<h1 mat-dialog-title>Warning</h1>
<div mat-dialog-content class="dialog-container">
  <p>If You unlock now, You will receive {{ data.punishment }}% less rewards</p>
</div>
<div mat-dialog-actions class="dialog-actions">
  <button mat-button class="default-button reject" (click)="onNoClick()">
    No Thanks
  </button>
  <button
    mat-button
    class="default-button confirm"
    [mat-dialog-close]="true"
    cdkFocusInitial
  >
    Ok
  </button>
</div>
