<style>.mat-button{color:green !important}</style>
<ng-container *ngIf="loaded; else loading">
  <form [formGroup]="form">
    <div class="first-row">
      <div class="select-container">
        <mat-form-field appearance="fill" class="mat-select">
          <mat-label>Enter flight number</mat-label>
          <input
            type="text"
            placeholder="Pick one"
            aria-label="Number"
            matInput
            formControlName="selectedFlight"
            [matAutocomplete]="auto"
          />
          <mat-autocomplete
            #auto="matAutocomplete"
            [displayWith]="getOptionText"
          >
            <mat-option
              *ngFor="let flight of filteredOptions | async"
              [value]="flight"
            >
              {{ flight.flightId }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-icon aria-hidden="false" aria-label="Example home icon" (click)="clearSelection()">backspace</mat-icon>
        <ng-container *ngIf="form.invalid">
          <span class="errorMessages">
            {{ form.controls.selectedFlight.errors?.msg }}
          </span>
        </ng-container>
      </div>
      <div class="info-box" *ngIf="informations$ | async as informations">
        <p class="info-box__value">{{ informations[0].value }}</p>
        <p class="info-box__name">{{ informations[0].name }}</p>
      </div>
    </div>

    <div class="input-row">
      <div class="input-container">
        <mat-form-field appearance="standard">
          <mat-label>SOL tokens</mat-label>
          <input
            name="inputAmount"
            formControlName="inputAmount"
            matInput
            placeholder="Enter Amount"
          />

          <mat-hint
            >Balance: {{ currentBalance | printToken: "9" }}
            <ng-container *ngIf="form.invalid">
              <span class="errorMessages">
                {{ form.controls.inputAmount.errors?.msg }}
              </span>
            </ng-container>
          </mat-hint>
        </mat-form-field>
        <button class="max-value" (click)="setMaxValue()">MAX</button>
      </div>
      <div
        *ngIf="expectedInsuranceReward$ | async as expectedInsuranceReward"
        class="info-box"
        [ngStyle]="{ opacity: !form.valid ? '0.2' : '1' }"
      >
        <p class="info-box__value">
          {{ expectedInsuranceReward | printToken: decimals }} (SOL)
        </p>
        <p *ngIf="expectedInsuranceRewardUsd$ | async as expectedInsuranceRewardUsd">
          ${{ expectedInsuranceRewardUsd | printToken: decimals }}
        </p>
        <p class="info-box__name">Compensation</p>
      </div>
    </div>
  </form>
  <button
    class="default-button claim_btn"
    mat-button
    (click)="buyInsurance()"
    [disabled]="!form.valid || isLoading"
  >
    <div *ngIf="!isLoading">Buy Insurance</div>
    <div *ngIf="isLoading" class="spinner">
      <mat-spinner diameter="28"> </mat-spinner>
    </div>
  </button>
</ng-container>
<ng-template #loading class="preloader-wrapper">
  <mat-spinner class="preloader-wrapper"></mat-spinner>
</ng-template>
