import { OraoMeta } from './orao.reducer';


export interface OraoState {
  orao: OraoMeta
}

export const selectOraoMeta = (state: OraoState) => {
  return state.orao
};
