<div class="balance-wrapper">
  <ng-container
    *ngIf="stakingContracts$ | async as stakingContracts"
  >
    <ng-container *ngIf="stakes">
      <div class="stake-container">
        <div class="title">
          <p class="deadline">Release date</p>
          <p class="period">Period</p>
          <p class="reward">Rewards</p>
          <p class="value">Locked</p>
          <p class="unstakeButton">Unstake</p>
        </div>
        <div *ngFor="let stake of stakes" class="stake">
          <p class="deadline">{{ stake.deadline | formatDate }}</p>
          <p class="period">
            {{ stake.period }} {{ stake.period === "1" ? "day" : "days" }}
          </p>
          <p class="reward">
            {{ stake.totalReward_d18 | printToken: "18" }}
          </p>
          <p class="value">{{ stake.value_d18 | printToken: "18" }}</p>
          <div class="unstakeButton">
            <button
              (click)="unstake(stake)"
              class="default-button"
              [disabled]="stakingContracts[stake.period].isLocked && !stake.readyToBeClaimed"
            >
              {{ stakingContracts[stake.period].isLocked && !stake.readyToBeClaimed ? 'Locked' : 'Unstake' }}
            </button>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
