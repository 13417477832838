import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { ClaimComponent } from './components/claim/claim.component';
import { DefiTokensModule } from '../defi-tokens/defi-tokens.module';
import { InteractionModule } from '../interaction/interaction.module';

@NgModule({
  declarations: [ClaimComponent],
  exports: [ClaimComponent],
  imports: [
    CommonModule,
    InteractionModule,
    MatTableModule,
    DefiTokensModule,
    MatProgressSpinnerModule
  ]
})
export class ClaimingModule { }
