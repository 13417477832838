<ng-container
  *ngIf="
    {
      balance: oraoBalance$ | async,
      decimals: oraoDecimals$ | async,
      claimablePerPool: oraoClaimablePerPool$ | async,
      orao: orao$ | async,
      stakes: stakes$ | async,
      stakingContracts: stakingContracts$ | async
    } as data;
    else loading
  "
>
  <ng-container
    *ngIf="
      data.balance &&
        data.decimals &&
        data.claimablePerPool &&
        data.orao &&
        data.stakes &&
        data.stakingContracts;
      else loading
    "
  >
    <div class="tokens-banalnce-wrapper">
      <div class="tokens-balance">
        <div class="row align-items-center justify-content-between orao-header">
          <div>
            <div class="powered_by">Powered by</div>
          </div>

          <div class="logo-wrapper">
            <img src="https://orao.network/_nuxt/img/logo.8e80d0c.svg" alt="" />
          </div>
        </div>

        <mat-tab-group animationDuration="0ms" [selectedIndex]="0">
          <mat-tab label="Stake">
            <ng-container *ngTemplateOutlet="stakeInput"></ng-container>
          </mat-tab>
          <mat-tab label="Current Stakes">
            <ng-container *ngTemplateOutlet="currentStake"></ng-container>
          </mat-tab>
        </mat-tab-group>

        <ng-template #currentStake>
          <app-current-stake
            [stakes]="data.stakes"
            (unstakeSubmit)="unstake($event)"
          ></app-current-stake>
        </ng-template>

        <ng-template #stakeInput>
          <app-staking-form
            [address]="data.orao.address"
            [currentBalance]="data.balance"
            [decimals]="data.decimals"
            [durationStakes]="data.stakingContracts"
            (submitStake)="stake($event)"
          >
          </app-staking-form>
        </ng-template>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #loading class="preloader-wrapper">
  <mat-spinner class="preloader-wrapper"></mat-spinner>
</ng-template>
